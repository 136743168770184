<template>
  <div class="order">
    <div class="handle-box">
      <el-date-picker
        v-model="value2"
        style="width: 240px"
        :clearable="false"
        :default-value="defaultDate"
        @change="changeTimer"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        type="daterange"
        align="right"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :shortcuts="shortcuts"
      >
        <!-- unlink-panels -->
      </el-date-picker>
      <span>用户ID:</span>
      <el-input
        v-model.trim="userId"
        placeholder="请输入用户ID"
        class="handle-input mr10 w100"
      ></el-input>
      <span>启用/禁用:</span>
      <el-select
        v-model="enable"
        placeholder="请选择"
        class="handle-select mr10 w80"
      >
        <el-option key label="全部" value></el-option>
        <el-option label="启用" value="1"></el-option>
        <el-option label="禁用" value="2"></el-option>
      </el-select>
      <span>手机号:</span>
      <el-input
        v-model.trim="mobile"
        placeholder="请输入手机号"
        class="handle-input mr10 w100"
      ></el-input>
      <!-- <span>客户端类型:</span>
      <el-input v-model.trim="clientId"
                placeholder="请输入客户端类型"
      class="handle-input mr10 w100"></el-input>-->
      <span>客户端类型:</span>
      <el-select
        v-model="clientId"
        placeholder="请选择"
        class="handle-select mr10 w80"
      >
        <el-option key label="全部" value></el-option>
        <el-option
          v-for="(item, i) in channelList"
          :key="i"
          :label="item.description"
          :value="item.clientId"
        ></el-option>
      </el-select>

      <span>渠道:</span>
      <el-select
        v-model="channelId"
        @change="channelChange"
        placeholder="请选择"
        class="handle-select mr10"
      >
        <el-option
          :key="item.id"
          v-for="item in channelDatas"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>

      <el-button
        type="primary ml10"
        style="marginleft: 10px"
        icon="el-icon-search"
        @click="searchData"
        >搜索</el-button
      >
      <el-button type="primary ml10" icon="el-icon-delete" @click="clearData"
        >清空</el-button
      >
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="用户ID" align="center" width="170">
          <template #default="scope">{{ scope.row.userId }}</template>
        </el-table-column>
        <el-table-column
          prop="userName"
          align="center"
          label="userName"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="nickName"
          align="center"
          width="140"
          label="nickName"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="mobile"
        ></el-table-column>
        <el-table-column
          prop="memberName"
          align="center"
          label="memberName"
        ></el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间">
          <template #default="scope">
            <span>{{
              scope.row.createTime &&
              scope.row.createTime
                .replace(/T/g, " ")
                .replace(/\.[\d]{3}Z/, "")
                .slice(0, 19)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="channel"
          align="center"
          label="渠道"
        ></el-table-column>
        <el-table-column
          prop="clientName"
          align="center"
          label="客户端类型"
        ></el-table-column>
        <el-table-column prop="id" align="center" label="操作" width="180">
          <template #default="scope">
            <el-button type="primary" @click="addVip(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button
              :type="scope.row.enable ? 'danger' : 'primary'"
              @click="enableDisable(scope.$index, scope.row)"
              >{{ scope.row.enable ? "禁用" : "启用" }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          background
          layout=" prev, pager, next"
          :current-page="pageIndex"
          :page-size="20"
          :total="total"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="加会员"
      v-model="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <!-- <p style="margin-bottom: 20px">请选择会员套餐</p>
      <el-select v-model="vipValue" placeholder="请选择">
        <el-option
          v-for="item in Combo"
          :key="JSON.stringify(item)"
          :label="item.productName"
          :value="JSON.stringify(item)"
        ></el-option>
      </el-select> -->
      <el-form ref="form" :model="query" label-width="180px">
        <el-form-item label="是否修改为永久会员:">
          <el-radio-group v-model="query.forever">
            <el-radio :label="Boolean(true)">是</el-radio>
            <el-radio :label="Boolean(false)">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="会员到期时间:">
          <el-date-picker
            v-model="timeValue1"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="积分:">
          <el-input v-model="query.point" type="Number"></el-input>
        </el-form-item>
        <el-form-item label="次数:">
          <el-input v-model="query.times" type="Number"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFn" v-loading="loadingVip"
          >添 加</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
// getDownExcel
import {
  clientConfig,
  getChannelList,
  refund,
  userList,
  getMember,
  // createAdminPay,
  optionUserStatus,
  modifyMember,
} from "../../api/index";
var md5 = require("../../utils/md5.js");
export default {
  data() {
    return {
      timeValue1: "",
      query: {
        forever: false,
        point: "",
        times: "",
      },
      enable: "",
      loadingVip: false,
      vipValue: "",
      userInfo: "",
      dialogVisible: false,
      name: "order",
      documentType: "",
      isEnable: "",
      categoryId: "",
      fatherList: [{ id: 1, name: "" }],
      SonId: "",
      sonList: [{ id: 1, name: "" }],
      value2: "",
      defaultDate: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
        selectableRange:
          "00:00:00 - " +
          new Date().getHours() +
          ":" +
          (new Date().getMinutes() + 1) +
          ":00",
      },

      shortcuts: [
        {
          text: "最近一周",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "最近一个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "最近三个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
      days: [],
      tableData: [],
      multipleSelection: [],
      orderStatus: [
        {
          name: "未付款",
          id: 0,
        },
        {
          name: "已付款",
          id: 1,
        },
        {
          name: "已退款",
          id: 2,
        },
        {
          name: "过期作废",
          id: 3,
        },
      ],
      payTypeList: [
        { name: "全部", id: "" },
        { name: "微信", id: 1 },
        { name: "支付宝", id: 2 },
      ],
      total: 0,
      pageIndex: 1,
      startTime: "",
      endTime: "",
      orderId: "",
      status: "",
      payType: "",
      tradeNo: "",
      userId: "",
      nickName: "",
      mobile: "",
      clientId: "",
      channelId: "", // 渠道id
      orderTypeId: "", //订单类型
      channelList: "", //渠道列表
      channelDatas: [], // 渠道下拉
      Combo: [],
    };
  },
  async mounted() {
    this.channelArray();
    await this.queryClientConfig();
    this.getData();
  },
  // 测试用户ID 1704
  methods: {
    formatDateTime(dateStr) {
      let date = new Date(dateStr);

      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);

      let formattedDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      return formattedDate;
    },
    enableDisable(index, row) {
      // /miaohui/admin/members/optionUserStatus/515
      console.log(index, row);
      let that = this;
      that
        .$confirm("是否确认" + (row.enable ? "禁用？" : "启用？"), "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          optionUserStatus(row.userId).then((res) => {
            console.log(res);
            if (res.code == 0) {
              that.$message.success(row.enable ? "禁用成功" : "启用成功");
              row.enable = !row.enable;
            } else {
              that.$message({
                type: "error",
                message: row.enable ? "禁用失败" : "启用失败",
              });
            }
          });
        });
    },
    addFn() {
      console.log("t", this.query);
      let d = "";
      if (this.timeValue1) {
        d = this.formatDateTime(this.timeValue1);
      }
      console.log("addFn", d);

      let q = {
        ...this.query,
        expiresOn: d,
        userId: this.userInfo.userId,
      };
      modifyMember(q).then((res) => {
        if (res.code == 0) {
          this.getData();
          this.dialogVisible = false;
          return this.$message({
            type: "success",
            message: "添加成功",
          });
        } else {
          return this.$message({
            type: "info",
            message: "添加失败",
          });
        }
      });
      console.log("q", q);
      // this.loadingVip = true;
      // let vipValue = JSON.parse(this.vipValue);
      // let clientUuid = this.getUuid(32, 16);
      // let key = "5e776136251349edafab59af837c031e";
      // let productId = vipValue.productId;
      // let orderTypeId = vipValue.orderTypeId;
      // let channel = this.userInfo.channel;
      // let userId = this.userInfo.userId;
      // let coid = 1;
      // let ncoid = 1;
      // let verCode = 1;
      // let str = `${key}${channel}${clientUuid}${coid}${ncoid}${orderTypeId}${productId}`;
      // console.log("sign", str);
      // let sign = md5(str).toUpperCase();
      // createAdminPay({
      //   productId,
      //   orderTypeId,
      //   channel,
      //   clientUuid,
      //   coid,
      //   userId,
      //   ncoid,
      //   verCode,
      //   sign,
      // }).then((res) => {
      //   if (res.code == 0) {
      //     this.dialogVisible = false;
      //     return this.$message({
      //       type: "success",
      //       message: "添加成功，请刷新页面!",
      //     });
      //   } else {
      //     return this.$message({
      //       type: "info",
      //       message: "添加失败",
      //     });
      //   }
      // });
      //
    },
    addVip(index, row) {
      console.log(index, row);
      this.userInfo = row;
      getMember(row.userId).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.dialogVisible = true;
          this.vipValue = "";
          this.loadingVip = false;
          this.query = {
            forever: res.data?.forever || false,
            point: res.data?.point || "",
            times: res.data?.times || "",
          };
          this.timeValue1 = res.data?.expiresOn || "";
        } else {
          this.$message({
            type: "error",
            message: "获取失败",
          });
        }
      });
    },
    getUuid(len, radix) {
      var chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
          ""
        );
      var uuid = [],
        i;
      radix = radix || chars.length;

      if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
      } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
        uuid[14] = "4";

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | (Math.random() * 16);
            uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
          }
        }
      }

      return uuid.join("");
    },
    refundFn(index, row) {
      // 退款
      this.$confirm("是否确认退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let key = "5e776136251349edafab59af837c031e";
          let amount = row.amount;
          let lenovoToken = "lenovoToken";
          let orderId = row.orderId;
          let userId = row.userId;
          let userName = "admin";
          let str = `${key}${amount}${lenovoToken}${orderId}${userId}${userName}`;
          console.log("sign", str);
          let sign = md5(str).toUpperCase();
          console.log("row", row);
          refund({ amount, lenovoToken, orderId, userName, sign, userId }).then(
            (res) => {
              console.log(99, res);
              if (res.code == 0) {
                this.$message.success("退款发起成功");
                // row.status = 2
              } else {
                return this.$message.error(res.message);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    channelArray() {
      getChannelList().then((res) => {
        console.log(99, res);
        if (res.code == 0) {
          this.channelDatas = res.data.filter((item) => {
            return item != "";
          });
          this.channelDatas.unshift("全部");
        }
      });
    },
    queryClientConfig() {
      clientConfig().then((res) => {
        if (res.code == 0) {
          this.channelList = res.data;
        }
        console.log("res", res);
      });
    },
    changeTimer(v) {
      //  格式化时间
      const d1 = new Date(v[0]);
      const d2 = new Date(v[1]);
      const resDate =
        d1.getFullYear() +
        "-" +
        this.p(d1.getMonth() + 1) +
        "-" +
        this.p(d1.getDate());
      const eDate =
        d2.getFullYear() +
        "-" +
        this.p(d2.getMonth() + 1) +
        "-" +
        this.p(d2.getDate());
      this.startTime = resDate + " 00:00:00";
      this.endTime = eDate + " 23:59:59";
      console.log(resDate, eDate);
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },

    searchData() {
      this.pageIndex = 1;
      this.getData();
    },
    clearData() {
      this.value2 = "";
      this.pageIndex = 1;
      this.startTime = "";
      this.endTime = "";
      this.orderId = "";
      this.status = "";
      this.payType = "";
      this.tradeNo = "";
      this.userId = "";
      this.nickName = "";
      this.clientId = "";
      this.orderTypeId = ""; //订单类型
      this.channelId = "";
      this.enable = "";
      this.getData();
    },
    getData() {
      let _enable = "";
      if (this.enable == 1) {
        _enable = 1;
      }
      if (this.enable == 2) {
        _enable = 0;
      }
      let params = {
        enable: _enable,
        startTime: this.startTime,
        endTime: this.endTime,
        status: this.status,
        userId: this.userId,
        nickName: this.nickName,
        clientId: this.clientId,
        pageIndex: this.pageIndex, //必需
        pageSize: 20, //必需
        mobile: this.mobile,
        orderTypeId: this.orderTypeId, //订单类型
        channel: this.channelId == "全部" ? "" : this.channelId,
      };
      userList(params).then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        this.total = res.data.total; // 总数
        console.log(this.tableData, "格式");
      });
    },
    pageChange(v) {
      this.pageIndex = v;
      this.getData();
      console.log("当前页", v);
    },
    changeLabel() {},
    fatherChange() {},
    channelChange() {},
    resetList() {},
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="scss">
.order {
  .handle-box {
    padding-top: 10px;
    .w100 {
      width: 180px;
    }
  }
  .table {
    padding-top: 20px;
  }
  .page {
    .el-pagination {
      text-align: center;
      padding-top: 20px;
    }
  }
}
</style>
